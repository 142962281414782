import FlexBox from "jtr-components/dist/common/FlexBox";
import React, { useState } from "react";
import styled from "styled-components";

import theme from "../resources/theme.json";

const Answer = styled.div`
  display: ${(props) => (props.renderComponent ? "block" : "none")};
  line-height: 32px;
  background: white;
  padding-top: 32px;
  padding-bottom: 16px;
  padding-left: 8px;
`;

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: white;
  background: ${(props) => (props.showAnswer ? theme.pink : theme.maroon)};
  font-family: ${theme.fonts.heading};
  padding: 8px;
`;

export const Disclaimer = styled.p`
  display: ${(props) => (props.renderComponent ? "block" : "none")};
  border: ${(props) => (props.color ? props.color : "black")} solid 2px;
  padding: 8px;
  background: white;
  line-height: 32px;
`;

const FAQItem = ({ question, answer, disclaimer, shown = false }) => {
  const [showAnswer, setShowAnswer] = useState(shown);

  const handleOnClick = () => {
    if (showAnswer) {
      setShowAnswer(false);
    } else {
      setShowAnswer(true);
    }
  };

  return (
    <div style={{ width: "100%", marginBottom: "16px" }}>
      <QuestionWrapper showAnswer={showAnswer} onClick={() => handleOnClick()}>
        <div style={{ fontSize: "larger", fontWeight: "600" }}>{question}</div>
        <div style={{ fontSize: "x-large", fontWeight: "600" }}>{showAnswer ? "-" : "+"}</div>
      </QuestionWrapper>
      <Answer renderComponent={showAnswer}>{answer}</Answer>
      {disclaimer ? (
        <Disclaimer color={"red"} renderComponent={showAnswer}>
          <strong>{"Important: "}</strong>
          {disclaimer}
        </Disclaimer>
      ) : null}
    </div>
  );
};

const HowItWorks = () => {

  return (
    <FlexBox flexDirection={"column"} width={"100%"} style={{ marginBottom: "32px" }}>
      <>
        <FlexBox flexDirection={"column"} width={"75%"}>
          <h1 style={{ fontFamily: "'Nunito', sans-serif" }}>How It Works</h1>
          <Disclaimer renderComponent={true}>
            <strong>{"Allergen Statement: "}</strong>
            {
              "Menu items produced in this facility may contain or come into contact with wheat, soy, eggs, peanuts, tree nuts, and dairy. Allergens will be indicated in the menu item descriptions."
            }
          </Disclaimer>
          <h2 style={{ fontFamily: "'Nunito', sans-serif" }}>Meal Prep</h2>
          <FAQItem question={"When does the menu come out?"} answer={"The new menu goes live every Friday."} />
          <FAQItem question={"How long do I have to order?"} answer={"Order by noon on Monday."} />
          <FAQItem question={"When is pickup?"} answer={"Pickup is available on Thursdays."} />
          <FAQItem
            question={"When is delivery?"}
            answer={
              "Deliveries are available on Thursdays. If you will not be home to accept the delivery we strongly urge you to purchase one of our insulated bags or place your own cooler outside with ice packs in it.  We will not be responsible for temperature related food spoilage due to client absence at the time of delivery."
            }
          />
          <h2 style={{ fontFamily: "'Nunito', sans-serif" }}>Made to Order Online Ordering</h2>
          <FAQItem
            question={"When is Online Ordering available?"}
            answer={"Online ordering is only available Thursday - Sunday. Delivery and takeout options are offered."}
          />
        </FlexBox>
      </>
    </FlexBox>
  );
};

export default HowItWorks;
