import React from "react";
import { initializeApp } from 'firebase/app';
import styled from "styled-components";

const firebaseConfig = {
	apiKey: "AIzaSyBDpgfBlJ1L2gbOS34c41sdfy-H2WFmiRo",
	authDomain: "utg-blono.firebaseapp.com",
	databaseURL: "https://utg-blono.firebaseio.com",
	projectId: "utg-blono",
	storageBucket: "utg-blono.appspot.com",
	messagingSenderId: "142637101666",
	appId: "1:142637101666:web:90cb7d0f2dc40e93",
	measurementId: "G-F744LE7K2G"
  };

export const FirebaseConfig = () => {
		initializeApp(firebaseConfig);

	return <FirebaseWrapper>{JSON.stringify(firebaseConfig)}</FirebaseWrapper>;
};

const FirebaseWrapper = styled.div`
	display: none;
`;
