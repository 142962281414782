import React from "react";

import theme from "../resources/theme.json";

const Header = ({ isMobile }) => {
  const flexDirection = isMobile ? "column" : "row";
  const imagePadding = isMobile ? "0px" : "64px";
  const maxWidth = !isMobile ? "100vw" : "768px";
  const fontSize = isMobile ? "medium" : "larger";

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: flexDirection,
          alignItems: "center",
          maxWidth: maxWidth,
          background: theme.black,
          color: "white",
        }}
      >
        <img
          src={"https://i.imgur.com/Iv0H8Ie.jpg"}
          alt="Under the Ground"
          style={{
            width: "100%",
            height: "100%",
            maxWidth: "calc(768px/2)",
            padding: `0px ${imagePadding}`,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 64px",
            fontSize: fontSize,
            lineHeight: "32px",
          }}
        >
          <p>
            <strong>Under the Ground Blono</strong>, or <strong>UtG</strong> offers a meal prep service operating out of Downs, IL.
          </p>

          <p>
            From healthy to homestyle, you are sure to find something to fit your taste buds. Whether you are a die hard vegan, vegetarian, transitioning to a
            plant-based lifestyle, or a carnivore just looking to reduce your consumption of animal products, this restaurant is for you!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
