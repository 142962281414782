import React from "react";
import styled from "styled-components";
import theme from "../resources/theme.json";

const FooterWrapper = styled.footer`
	display: flex;
	flex-direction: column;
	width: 100%;
	background: ${theme.black};
	padding: 16px;
	width: calc(100vw - 32px);
	color: white;
	align-items: center;
`;

const Footer = () => {
	return (
		<FooterWrapper>
			<p style={{ fontFamily: "'Playfair Display SC', serif", fontSize: "x-large", textAlign: "center", fontWeight: "900" }}>{"Word on the street is you won't miss the meat!"}</p>
			<p style={{ fontSize: "large", fontFamily: theme.heading, textAlign: "center" }}>{"Bloomington, IL est. 2019"}</p>
		</FooterWrapper>
	);
};

export default Footer;
