import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Disclaimer } from "../pages/HowItWorks";
import ReadersChoice from "../svg/readers-choice.png";
import UtgFood1 from "../svg/utg-food-1.jpg";
import UtgFood2 from "../svg/utg-food-2.jpg";
import UtgFood3 from "../svg/utg-food-3.jpg";
import UtgFood4 from "../svg/utg-food-4.jpg";

const images = [
  {
    original: ReadersChoice,
  },
  {
    original: "https://i.imgur.com/jExnAQW.jpg",
  },
  {
    original: "https://i.imgur.com/ZyX2NrB.jpg",
  },
  {
    original: UtgFood1,
  },
  {
    original: "https://i.imgur.com/s6ic8DH.jpg",
  },
  {
    original: UtgFood2,
  },
  {
    original: "https://i.imgur.com/VJqz6Xp.jpg",
  },
  {
    original: UtgFood3,
  },
  {
    original: "https://i.imgur.com/yqXyFN2.jpg",
  },
  {
    original: UtgFood4,
  },
  {
    original: "https://i.imgur.com/vWkefAP.jpg",
  },
];

const Gallery = () => {
  return (
    <Disclaimer renderComponent>
      <div style={{ margin: "16px" }}>
        <ImageGallery items={images} autoPlay slideDuration={2000} slideInterval={5000} />
      </div>
    </Disclaimer>
  );
};

export default Gallery;
