import FacebookLogo from "../svg/facebook-social.png";
import InstagramLogo from "../svg/instagram-social.png";
import TikTokLogo from "../svg/tiktok-social.png";
import SignUpNowButton from "./SignUpNowButton";

const SocialMediaModule = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={{ fontSize: "32px", margin: "16px" }}>Connect with us on social media!</div>
      <div>
        <a href="https://www.facebook.com/UtGBloNo/">
          <img alt="Facebook Logo" src={FacebookLogo} height="72" style={{ margin: "16px" }} />
        </a>
        <a href="https://www.instagram.com/utgblono">
          <img alt="Instagram Logo" src={InstagramLogo} height="72" style={{ margin: "16px" }} />
        </a>
        <a href="https://www.tiktok.com/@utgblono">
          <img alt="TikTok Logo" src={TikTokLogo} height="72" style={{ margin: "16px" }} />
        </a>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          margin: "16px",
        }}
      >
        <SignUpNowButton />
      </div>
    </div>
  );
};

export default SocialMediaModule;
