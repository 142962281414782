import React from "react";
import styled from "styled-components";
import theme from "../resources/theme.json"

const CenteredText = styled.button`
	background: white;
	padding: 16px;
	border: 4px solid ${theme.maroon};
	color: ${theme.maroon};
	font-weight: 600;
	width: fit-content;
	text-decoration: none;
	font-family: ${theme.fonts.heading};
	cursor: pointer;
	font-size: 24px;
	border-radius: 45px;

	:hover,
	:active,
	:focus {
		border: 4px solid ${theme.pink};
	}
`;

const SignUpNowButton = () => {
	const handleOnClick = () => {
		setTimeout(() => {
			window.open("https://mailchi.mp/eea55874cebc/sign-up", "_blank");
		}, 200);
	};

	return (
		<CenteredText
			onClick={() => {
				handleOnClick();
			}}
		>
			{"Sign up for emails".toUpperCase()}
		</CenteredText>
	);
};

export default SignUpNowButton;
