import { Disclaimer } from "../pages/HowItWorks";

const Location = () => {
  return (
    <Disclaimer renderComponent style={{ margin: "16px" }}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{ fontSize: "20px", margin: "16px" }}>We are located at 2 River Run Suite 4 Downs, IL.</div>
      </div>
    </Disclaimer>
  );
};

export default Location;
